/**
 * Created by koko on 20/01/16.
 */
"use strict";

$.ajaxSetup({
    headers: { 'X-CSRF-Token' : $('meta[name=csrf_token]').attr('content') }
});

$.extend({
    validMail: function(emailAddress) {
        var pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})]?$)/i;
        return pattern.test(emailAddress);
    },
    validForm: function () {
        var name = $('#name');
        var surname = $('#surname');
        var email = $('#email');
        var phone = $('#phone');
        var allGood = true;
        if(name.val().length < 2) {
            name.addClass('error');
            allGood = false;
        }

        if(surname.val().length < 2) {
            surname.addClass('error');
            allGood = false;
        }

        if(phone.val().length < 2) {
            phone.addClass('error');
            allGood = false;
        }

        if(!$.validMail(email.val())) {
            email.addClass('error');
            allGood = false;
        }

        return allGood;
    }
});

var USER = null;
$(function() {

    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    $(window).on('resize', function() {
        w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    });

    $(document).on('click', '.go, .repeat', function (event) {
        event.preventDefault();
        $('.q, .block').addClass('hidden');
        $('.q').eq(0).removeClass('hidden');
        $('.block').eq(0).removeClass('hidden');


        $(this).fadeOut();
        $('.carrot').fadeOut();
        $('.user').fadeIn();
        $('.fail').fadeOut();
        $('.mobile').addClass('sm');
    });

    $(document).on('submit', '#user-reg-form', function (event) {
        event.preventDefault();
    });

    $(document).on('click', '.register-btn', function (event) {
        event.preventDefault();
        if($.validForm()) {
            $.post(BASE_URL + 'ajax/register', $('#user-reg-form').serializeArray(), function (response) {
                if(! response.error) {
                    USER = response;
                    $('.user').fadeOut(function () {
                        $('.upper').addClass('up');
                        $('.logo').addClass('small');
                        $('.mobile').addClass('registered');
                    });
                }
            }, 'json');
        }
    });

    $(document).on('focus', 'input', function () {
        $(this).removeClass('error');
    });

    $(document).on('click', '.answer', function (event) {
        event.preventDefault();
        var el = $(this);
        el.addClass('active');
        $.post(BASE_URL + 'ajax/answer', {
            q: el.data('q'),
            a: el.data('a'),
            test: el.data('test')
        }, function (response) {
            if(! response.error) {
                var thisBlock = el.parent();
                var thisIdx = thisBlock.index('.block');
                var nextIdx = thisIdx + 1;
                if(nextIdx > 9) { //done screen
                    $('.upper').removeClass('up');
                    $('.mobile').removeClass('registered');
                    if(response.all_right) { //viss ok
                        setTimeout(function () {
                            $('.done').fadeIn();
                        }, 450);
                        $('.mobile').removeClass('sm');
                    } else {
                        for(var i = 1; i <= 10; i++) {
                            if(response.right_answers[i] == 0) {
                                $('.bul' + i).addClass('wrong');
                            } else {
                                $('.bul' + i).removeClass('wrong');
                            }
                        }

                        setTimeout(function () {
                            $('.fail').fadeIn();
                        }, 450);
                    }
                } else {
                    $('.question').addClass('changing');
                    setTimeout(function () {
                        $('.question').removeClass('changing');
                    }, 260);

                    $('.bullet').removeClass('active');
                    $('.bullet').eq(nextIdx).addClass('active');
                    $('.q' + el.data('block')).addClass('hidden');
                    $('.b' + el.data('block')).addClass('hidden');
                    $('.q').eq(nextIdx).removeClass('hidden');
                    $('.block').eq(nextIdx).removeClass('hidden');
                }
            }
        }, 'json');
    });

    var shareLink = 'https://www.vivus.lv/blog/vip2017/?utm_source=facebook&utm_medium=share&utm_campaign=vip_lv';
    if(CUR_LANG === 'ru') {
        shareLink = 'https://www.vivus.lv/ru/blog/vip2017/?utm_source=facebook&utm_medium=share&utm_campaign=vip_ru';
    }

    $(document).on('click', '.share', function (event) {
        event.preventDefault();
        window.open( 'https://www.facebook.com/sharer/sharer.php?u=' + shareLink,
            'fbShareWindow',
            'toolbar=no,location=no,zstatus=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=400');
    });


    $(document).on('click', '.rules', function (event) {
        event.preventDefault();
        $('#rueles').fadeIn();
    });
    $(document).on('click', '.winners', function (event) {
        event.preventDefault();
        $('#winners').fadeIn();
    });

    $(document).on('click', '.close', function (event) {
        event.preventDefault();
        $(this).parent().fadeOut();
    })

});